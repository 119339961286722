<!--
 * @Date: 2023-10-18 10:19:27
 * @LastEditTime: 2024-02-23 19:02:41
 * @Description: 1024换衣、消消乐使用
-->
<template>
  <div>
    <div class="img-upload-1024">
      <div class="img-upload-title">
        <slot name="title">
          <img v-if="titleIcon" :src="titleIcon" width="25px" height="28px" />
          {{ title }}
        </slot>
      </div>
      <!-- <ElPopover
        placement="right-start"
        width="640"
        :disabled="!tipsShow"
        trigger="hover"
      >
        <img
          style="width: 604px; height: 488px"
          src="../../assets/img/1024/tips.png"
        /> -->
      <el-upload
        slot="reference"
        :action="action"
        class="imgUpload"
        auto-upload:false
        list-type="picture-card"
        :multiple="multiple"
        :show-file-list="false"
        :headers="headers"
        accept="image/jpg,image/png,image/jpeg"
        :file-list="fileList"
        :on-remove="handleRemove"
        :on-change="handleChange"
        :on-success="success"
      >
        <!-- :before-upload="changeFile" -->
        <div class="upload-img">
          <img type="default" src="@/assets/img/1024/uploadimg.png" /><img
            type="hover"
            src="@/assets/img/1024/uploadimg_hover.png"
            alt=""
          />
          <p>{{ uploadText }}</p>
        </div>
      </el-upload>
      <!-- </ElPopover> -->

      <div class="tap-slide tap-up" @click="handleSlide('up')">
        <img src="@/assets/img/1024/tap_up.png" alt="上翻" />
      </div>
      <div
        class="logoExample"
        ref="logoExample"
        :class="{ prohibitClicking: prohibitClicking == true }"
      >
        <div class="logoExample-wrap" ref="logoList">
          <div
            class="logoExampleMain"
            v-for="(item, index) in fileList"
            :key="index"
            @click="selectPicture(index, item.url)"
            :class="{ imgActive: index === modelIndex }"
          >
            <img :src="item.url" />
          </div>
        </div>
      </div>
      <div class="tap-slide tap-down" @click="handleSlide('down')">
        <img src="@/assets/img/1024/tap_down.png" alt="下翻" />
      </div>
    </div>
  </div>
</template>

<script>
import { apiUri } from '@/api/txt';
export default {
  name: 'imgUpload1024',
  props: {
    // 图片列表
    imgList: {
      type: Array,
      default: () => [],
    },
    // 标题
    title: '',
    // 已选索引
    modelIndex: {
      type: Number,
      default: -2,
    },
    // 上传是否多选
    multiple: {
      type: Boolean,
      default: false,
    },
    // 滚动列表至顶部
    listScrollTop: {
      type: Boolean,
      default: false,
    },
    // 上传框文字
    uploadText: {
      type: String,
      default: '上传图片',
    },
    // 是否显示提示
    tipsShow: {
      type: Boolean,
      default: false,
    },
    // 标题icon
    titleIcon: {
      type: String,
      default: '',
    },
  },
  computed: {
    // fileList: {
    //   get() {
    //     return this.imgList
    //   },
    //   set(val) {
    //     this.$emit('update:fileList', val);
    //   }
    // }
  },
  watch: {
    /**
     * @description: 列表滚动制顶部
     * @param {*} val
     * @return {*}
     */
    listScrollTop(val) {
      if (val) {
        this.scrollDistance = 0;
        this.$refs.logoExample.scrollTo({
          top: this.scrollDistance,
          left: 0,
          behavior: 'smooth',
        });
        // this.$refs.logoList.style.transform=`translateY(${this.scrollDistance}px)`;
        this.$nextTick(() => {
          this.$emit('update:listScrollTop', false);
        });
      }
    },
  },
  data() {
    return {
      // 额外header信息
      headers: {
        token: localStorage.getItem('tokenKey')
          ? localStorage.getItem('tokenKey')
          : '',
      },
      // 图片上传地址
      action: `${apiUri}/upload/uploadFile`,
      // action:`${apiUri}/img/imgUpload`, // 老的图片上传接口
      fileList: this.imgList,
      // 加载时，禁止点击
      prohibitClicking: false,
      // 当前选中的图片
      imgCurrentActive: -2,
      // 滚动距离
      scrollDistance: 0,
    };
  },
  methods: {
    /**
     * @description: 上传文件
     * @return {*}
     */
    changeFile() {
      this.$message.info('暂不支持自定义上传');
      return false;
    },
    /**
     * @description: 列表选择图片
     * @param {*} index
     * @param {*} img
     * @return {*}
     */
    selectPicture(index, img) {
      this.$emit('select', index, img);
    },
    /**
     * @description: 上传文件发生改变时
     * @param {*} file
     * @param {*} fileList
     * @return {*}
     */
    handleChange(file, fileList) {
      this.$emit('change', file, fileList);
    },
    /**
     * @description: 上传成功
     * @param {*} response
     * @param {*} file
     * @param {*} fileList
     * @return {*}
     */
    success(response, file, fileList) {
      console.log('response', response);
      this.$emit('success', response, file, fileList);
    },
    /**
     * @description: 文件列表移除文件时的钩子
     * @param {*} file
     * @param {*} fileList
     * @return {*}
     */
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    /**
     * @description: 控制列表滑动-每次一屏
     * @param {*} action
     * @return {*}
     */
    handleSlide(action) {
      const unitHeight = 142 * 4; // 一次滑动一屏
      let temp = this.$refs.logoExample.clientHeight;
      const maxDistance = this.$refs.logoList.scrollHeight - temp;
      if (maxDistance < 0) return;
      console.log(maxDistance, temp);
      if (action == 'up') {
        // const temp = this.scrollDistance - unitHeight;
        this.scrollDistance =
          this.scrollDistance - temp > 0 ? this.scrollDistance - temp : 0;
        this.$refs.logoExample.scrollTo({
          top: this.scrollDistance,
          left: 0,
          behavior: 'smooth',
        });
        // this.$refs.logoList.style.transform = `translateY(${this.scrollDistance}px)`;
      } else if (action == 'down') {
        // const temp = this.scrollDistance + unitHeight;
        this.scrollDistance =
          this.scrollDistance + temp > maxDistance
            ? maxDistance
            : this.scrollDistance + temp;
        console.log(this.scrollDistance);
        this.$refs.logoExample.scrollTo({
          top: this.scrollDistance,
          left: 0,
          behavior: 'smooth',
        });
        // this.$refs.logoList.style.transform = `translateY(${this.scrollDistance}px)`;
      }
    },
  },
};
</script>

<style scoped lang="less">
* {
  margin: 0;
}
.img-upload-1024 {
  position: relative;
  /* padding-top: 32px; */
  height: 100%;
  transform: translateY();
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.img-upload-title {
  color: #000108;
  font-size: 20px;
  padding-bottom: 9px;
  height: 37px;
  box-sizing: border-box;
}
.img-upload-title img {
  vertical-align: top;
}

.logoExample {
  height: calc(100% - 180px);
  overflow-y: auto;
}
.logoExample-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  transition: transform 1s;
}

.logoExampleMain {
  flex-shrink: 0;
  width: 130px;
  height: 130px;
  line-height: 130px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
  border: 1.5px solid #fff;
}

.logoExampleMain img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
}
.tap-slide {
  width: 100%;
  padding: 9px 0 8px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
}
.tap-slide:hover {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
}
.tap-slide img {
  height: 7px;
  width: 14px;
}
.tap-up {
  margin-bottom: 12px;
}
.tap-down {
  position: absolute;
  bottom: -36px;
}
.upload-img {
  display: flex;
  flex-direction: column;
  flex-basis: 50px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: rgba(93, 95, 108, 1);
  cursor: pointer;
  padding-top: 4px;
}
.upload-img img {
  width: 24px;
  height: 20px;
  margin-bottom: 5px;
}
.upload-img p {
  /* width: 100%; */
  line-height: 20px;
}
.upload-img img[type='hover'] {
  display: none;
}
.imgActive {
  border-color: #6c38e0;
}
::-webkit-scrollbar {
  width: 0px; /* 设置滚动条宽度 */
}

.imgUpload {
  width: 130px;
  height: 130px;
  margin-bottom: 12px;
  /deep/ .el-upload--picture-card {
    width: 130px;
    height: 130px;
    line-height: 130px;
    background-color: transparent;
    border-color: #5d5f6c;
  }
  /deep/ .el-upload--picture-card:hover {
    border-color: #000;
  }
  /deep/.el-upload--picture-card:hover .upload-img {
    color: #000108;
  }
  /deep/ .el-upload--picture-card:hover .upload-img img {
    display: none;
  }
  /deep/ .el-upload--picture-card:hover .upload-img img[type='hover'] {
    display: block;
  }
  /deep/ .el-upload--picture-card:hover .upload-img img {
    width: 24px;
    height: 20px;
    margin-bottom: 5px;
  }
}
</style>
